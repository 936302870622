import { StarIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import CustomImage from 'shared/components/CustomImage'
import ForAdvisorBtnIcon from 'views/homepage/icons/ForAdvisorBtnIcon'
import ForArtistBtnIcon from 'views/homepage/icons/ForArtistBtnIcon'
import ForCollectorBtnIcon from 'views/homepage/icons/ForCollectorBtnIcon'
import CTAButton from '../CTAButton'
import GlobeIconWithCircle from 'views/homepage/icons/GlobeIconWithCircle'

type Props = {
  isLoggedIn: boolean
  isAuthFetching: boolean
}

const TopFold = (props: Props) => {
  const { isLoggedIn, isAuthFetching } = props
  const ScreenShotView = (
    <>
      <CustomImage
        src="/images/homepage/hero-section/iphone.webp"
        fill
        className="relative z-[2] object-contain object-left"
        alt="cohart on iphone"
        priority
        sizes="(max-width: 768px) 100vw, 20vw"
      />
      <ForAdvisorBtnIcon className="absolute -bottom-7 -right-[25%] lg:-right-[65%] lg:scale-[1.36] xl:-right-[55%] xl:scale-[1.7]" />
      <ForArtistBtnIcon className="absolute -right-[40%] bottom-2/3 lg:-right-[65%] lg:scale-[1.36] xl:scale-[1.7]" />
      <ForCollectorBtnIcon className="absolute -left-1/3 bottom-1/3 z-[3] lg:-left-[75%] lg:scale-[1.36] xl:-left-[80%] xl:scale-[1.7]" />
    </>
  )
  return (
    <div className="relative flex flex-1 flex-col justify-end lg:h-[90vh] lg:max-h-screen lg:min-h-[600px] xl:min-h-[700px] 2xl:h-[85vh]">
      <div className="lg:hidden">
        <CustomImage
          fill
          src="/images/homepage/hero-section/hero-banner-mobile.webp"
          quality={100}
          priority
          sizes="100vw"
          className="absolute left-0 top-0  h-full w-full object-cover"
        />
      </div>

      <div className="hidden lg:block">
        <CustomImage
          fill
          src="/images/homepage/hero-section/hero-banner-desktop.webp"
          quality={100}
          priority
          sizes="100vw"
          className="absolute left-0 top-0  h-full w-full object-cover"
        />
      </div>

      <div className="relative z-[2] mt-[120px] grid w-full grid-cols-1 gap-6 lg:h-[calc(100vh-120px)] lg:grid-cols-2 lg:gap-0">
        <div className="flex h-full flex-col justify-center px-[23px] lg:px-[64px]">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
            className="hidden lg:block"
          >
            {/* desktop title */}
            <h1
              className={classNames(
                'font-inter text-[48px] font-medium leading-[50%]',
                'text-[#191414] xl:text-[72px] xl:leading-[80%] xl:tracking-[-1%]',
              )}
            >
              <span className="whitespace-nowrap">We bring the art</span>
              <br />
              <span className="flex items-center gap-0 xl:gap-5">
                world
                <GlobeIconWithCircle className="xl:scale-[1.20]" />
                online.
              </span>
            </h1>
          </motion.div>
          {/* mobile title */}
          <h1 className="font-inter text-[48px] font-medium leading-[80%] text-[#191414] lg:hidden">
            We bring the
            <br />
            <span className="flex items-center">
              art
              <GlobeIconWithCircle className="size-[80px]" />
              world
            </span>
            online.
          </h1>

          <div className="mt-[25px] space-y-2 xl:mt-[37px]">
            <p className="text-[10px] uppercase text-[#898788] opacity-50 lg:text-sm xl:text-base">Featured In</p>
            <div className="relative -ml-3 hidden h-[45px] w-[400px] lg:block lg:h-[50px] lg:w-[450px] xl:-ml-4 2xl:h-[60px] 2xl:w-[700px]">
              <CustomImage
                src="/images/homepage/hero-section/featured-in-newspaper-desktop.webp"
                fill
                className="object-contain object-left 2xl:object-cover"
                alt="featured in newspaper"
                sizes="(max-width: 768px) 100vw, 20vw"
                priority
              />
            </div>
            <div className="relative -ml-2 h-[35px] w-full max-w-[420px] lg:hidden">
              <CustomImage
                src="/images/homepage/hero-section/featured-in-newspaper-mobile.webp"
                fill
                className="object-contain object-left"
                alt="featured in newspaper"
                sizes="(max-width: 768px) 100vw, 20vw"
                priority
              />
            </div>
          </div>
          <div className="mt-4 flex items-center lg:mt-8">
            <div className="flex gap-x-2">
              <div className="max-w-[72px]">
                <CustomImage
                  src="/images/homepage/top-fold/review-avatar.webp"
                  alt="avatar reviewer"
                  width={145}
                  height={92}
                  quality={100}
                  sizes="(max-width: 768px) 30vw, 20vw"
                  priority
                />
              </div>
              <div>
                <div className="flex">
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                </div>
                <p className="text-[10px] text-[#898788] lg:text-xs">Trusted and loved by many ;)</p>
              </div>
            </div>
          </div>
          <div className="mt-7">
            <CTAButton
              href="/discover"
              title={isLoggedIn ? 'Start Discovering' : 'Get Started'}
              className={isAuthFetching ? 'invisible' : ''}
            />
          </div>
        </div>
        <div className="flex h-full w-full flex-col items-center justify-center">
          <motion.div
            className={classNames(
              'relative mb-10 hidden lg:block',
              'lg:mb-0 lg:h-[375px] lg:w-[185px] xl:h-[500px] xl:w-[247px]',
            )}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0, transition: { delay: 0.3 } }}
          >
            {ScreenShotView}
          </motion.div>
          <div className="relative mb-10 block h-[395px] w-[195px] lg:hidden">{ScreenShotView}</div>
        </div>
      </div>
    </div>
  )
}
export default TopFold
